/* global Swiper */
// Import polyfill from node_modules
// import 'core-js/modules/es6.set.js';
// import 'core-js/modules/es6.array.find-index.js';

import { on, off, isInViewport, throttle } from './utils/_fn';

(function(window, document) {
	/* ---------------------------------------- [START] 整頁 Scroll 監測 (Before) */
	var pageScrollCheckList = []; // 紀錄物件
	var pageScrollAdd = function (selector, buildFunction) {
		var el = document.querySelector(selector);
		if (el === null) {
			return false;
		}
		pageScrollCheckList.push({
			build: false,
			el: el,
			fun: buildFunction,
		});
	};
	var pageScrollClear = function () {
		pageScrollCheckList = Array.prototype.filter.call(pageScrollCheckList, function (item) {
			return item.build === false;
		});
	};
	/* ---------------------------------------- [END] 整頁 Scroll 監測 (Before) */

	/* ---------------------------------------- [START] Banner  */
	const indexBannerCarousel = '#index-banner-carousel';
	function indexBannerBuild() {
		const swiperTarget = indexBannerCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperNextEl = swiperEl.querySelector('.swiper-button-next');
		const swiperPrevEl = swiperEl.querySelector('.swiper-button-prev');
		const swiperPageEl = swiperEl.querySelector('.swiper-pagination');

		const swiper = new Swiper(swiperTarget, {
			slidesPerView: 1,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: swiperPageEl,
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 3,
			},
			navigation: {
				nextEl: swiperNextEl,
				prevEl: swiperPrevEl,
			}
		});
	}

	function getScrollTop(target = window) {
		return (target.scrollY || html.scrollTop) - (html.clientTop || 0);
	}

	const html = document.documentElement;

	// let startScroll = false;
	function indexBannerScrollAni() {
		let ws = getScrollTop();
		let startScroll = false; // 新增這行初始化 startScroll 變數
	
		const startScrollChecker = function startScrollChecker() {
			if (!startScroll) {
				startScroll = true;
				html.classList.add('has-scrolled');
			}
			off(window, 'scroll', startScrollChecker);
		}
		on(window, 'scroll', startScrollChecker);
		
		on(window, 'scroll', function() {
			ws = getScrollTop();
			html.style.setProperty('--ws', `${ws}px`);
		});
	}
	on(window, 'load', function () {
		indexBannerScrollAni();
		pageScrollAdd(indexBannerCarousel, indexBannerBuild);
	});
	
	/* ---------------------------------------- [END] Banner */

	/* ---------------------------------------- [START] Project  */
	let indexProjIntroEl = null; // 顯示內容介紹的物件
	let indexProjDesinerItem = null; // 讀取設計師列表資料（原始未生成輪播項目）
	let indexProjBg = null; // 放置背景圖物件
	
	const indexProjGalleryCarousel = '#index-proj-gallery-carousel';
	let indexProjGalleryWrapper = null; // 取得Gallery列表<ul>
	let indexProjGallerySwiper = null; // 紀錄 Gallery Swiper
	let indexProjGallerySlideTmp = null; // 取得作品集模板
	
	const indexProjDesignerCarousel = '#index-proj-designer-carousel';
	let indexProjDesigerSwiper = null;

	let currentContEl = null; // 紀錄目前的物件

	// 取得物件
	function indexProjInitContent() {
		// 基礎項目
		indexProjIntroEl = document.getElementById('index-proj-intro');
		indexProjDesinerItem = document.querySelectorAll('.index-proj__designer__item');
		indexProjBg = document.getElementById('index-proj-bg');

		// 取得作品集基礎項目
		indexProjGalleryWrapper = document.querySelector(`${indexProjGalleryCarousel} .swiper-wrapper`);

		const indexProjGalleryItemHTML = indexProjGalleryWrapper.innerHTML.trim();

		indexProjGallerySlideTmp = indexProjGalleryItemHTML.split('</'); // ['<li class="xxxx">', 'li>']
		indexProjGallerySlideTmp[1] = `</${indexProjGallerySlideTmp[1]}`;

		indexProjAddContent(0);
	}

	function indexProjAddContent(index) {
		currentContEl = indexProjDesinerItem[index].querySelector('.index-proj__designer__cont'); // 取得內容
		indexProjIntroEl.innerHTML = currentContEl.innerHTML; // 生內容
		addIndexProjPhotoSlide(currentContEl.dataset.gallery); // 增加作品圖片
	}

	// 增加圖片
	function addIndexProjPhotoSlide(gallery) {
		if (!!indexProjGallerySwiper && gallery.length) {
			const slideArray = [];
			const data = JSON.parse(gallery);
			data.forEach( function(item) {
				slideArray.push(`
					${indexProjGallerySlideTmp[0]}
						<a class="img-absolute" href="${item.href || 'javascript:void(0)'}">
							<img src="${item.img}" alt="${item.alt || ''}" data-swiper-parallax="300" loading="lazy">
						</a>
					${indexProjGallerySlideTmp[1]}
				`)
			});
			indexProjGallerySwiper.appendSlide(slideArray);
			indexProjGallerySwiper.slideTo(0);
		}
	}

	function setIndexProBgImg(img) {
		indexProjBg.style.backgroundImage = `url(${img})`;
	}

	// 作品圖片輪播
	let indexProjGalleryTimer = null;
	function indexProjGalleryCarouselBuild() {
		const swiperTarget = indexProjGalleryCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperPageEl = swiperEl.querySelector('.swiper-pagination');

		indexProjGallerySwiper = new Swiper(swiperTarget, {
			spaceBetween: 20,
			loop: true,
			parallax: true,
			pagination: {
				el: swiperPageEl,
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 3,
			},
			on: {
				// 初始建立
				afterInit: function() {
					setTimeout(() => {
						this.removeAllSlides();
						addIndexProjPhotoSlide(currentContEl.dataset.gallery.split(',')); // 增加作品圖片
					}, 500)
				},
				slideChange: function(sw) {
					if (indexProjGalleryTimer) {
						clearTimeout(indexProjGalleryTimer);
					}
					indexProjGalleryTimer = setTimeout(() => {
						const currentSlide = sw.visibleSlides[0];
						if (!!currentSlide) {
							const img = currentSlide.querySelector('img');
							const src = img.getAttribute('src');
							setIndexProBgImg(src)
						}
					}, 100);
				}
			}
		});
	}

	function indexProjDesignerCarouselBuild() {
		const swiperTarget = indexProjDesignerCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;

		const eventOps = {
			slideChange: function(sw) {
				indexProjGallerySwiper.removeAllSlides();
				indexProjAddContent(sw.realIndex);
			}
		};

		const largeOps = {
			spaceBetween: 45,
			slidesPerView: 'auto',
			centeredSlides: true,
			// loop: true, // 開Loop會壞掉
			// simulateTouch: false, // 關閉滑鼠拖曳
			slideToClickedSlide: true,
			effect: 'creative',
			creativeEffect: {
				limitProgress: 4,
				prev: {
					translate: ["-150%", '-50%', -800],
					scale: 0.9,
				},
				next: {
					translate: ["150%", '-50%', -800],
					scale: 0.9,
				},
			},
			on: eventOps
		}

		const smallOps = {
			slidesPerView: 'auto',
			loop: true,
			loopedSlides: 2,
			slideToClickedSlide: true,
			centeredSlides: true,
			effect: 'creative',
			creativeEffect: {
				progressMultiplier: 1,
				limitProgress: 3,
				prev: {
					translate: ["-180%", '-70%', -800],
					scale: 0.95,
				},
				next: {
					translate: ["180%", '-70%', -800],
					scale: 0.95,
				},
			},
			on: eventOps
		}

		

		const breakpoint = window.matchMedia('(min-width: 1024px)');
		const breakpointChecker = function() {
			if (!!indexProjDesigerSwiper) {
				indexProjDesigerSwiper.destroy(true, true);
			}
			indexProjDesigerSwiper = new Swiper(swiperTarget, breakpoint.matches ? largeOps : smallOps);
		}

		breakpoint.addEventListener('change', breakpointChecker);
		breakpointChecker();
	}

	function indexProjBuild() {
		// 生成第一項目的內容
		// indexProjInitContent();
		indexProjGalleryCarouselBuild();
		indexProjDesignerCarouselBuild();
	}

	on(window, 'load', function () {
		indexProjInitContent();
		pageScrollAdd('#index-proj', indexProjBuild);
	});
	/* ---------------------------------------- [END] Project */

	/* ---------------------------------------- [START] Article  */
	const indexArticleCarousel = '#index-article-carousel';
	function indexArticleBuild() {
		const swiperTarget = indexArticleCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperNextEl = swiperEl.querySelector('.swiper-button-next');
		const swiperPrevEl = swiperEl.querySelector('.swiper-button-prev');

		const swiper = new Swiper(swiperTarget, {
			slidesPerView: 1,
			spaceBetween: 45,
			navigation: {
				nextEl: swiperNextEl,
				prevEl: swiperPrevEl,
			},
			breakpoints: {
				// >= 320px
				640: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},
				// >= 640px
				1024: {
					slidesPerView: 3,
					slidesPerGroup: 3,
				}
			}
		});
	}
	on(window, 'load', function () {
		pageScrollAdd(indexArticleCarousel, indexArticleBuild);
	});
	/* ---------------------------------------- [END] Article */

	/* ---------------------------------------- [START] Review  */
	const indexReviewCarousel = '#index-review-carousel';
	function indexReviewBuild() {
		const swiperTarget = indexReviewCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperPageEl = swiperEl.querySelector('.swiper-pagination');

		const swiper = new Swiper(swiperTarget, {
			pagination: {
				el: swiperPageEl,
				clickable: true,
			},
		});
	}
	on(window, 'load', function () {
		pageScrollAdd(indexReviewCarousel, indexReviewBuild);
	});
	/* ---------------------------------------- [END] Review */

	/* ---------------------------------------- [START] Partner  */
	const indexPartnerCarousel = '#index-partner-carousel';
	function indexPartnerBuild() {
		const swiperTarget = indexPartnerCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperNextEl = swiperEl.querySelector('.swiper-button-next');
		const swiperPrevEl = swiperEl.querySelector('.swiper-button-prev');

		const swiper = new Swiper(swiperTarget, {
			slidesPerView: 'auto',
			spaceBetween: 20,
			navigation: {
				nextEl: swiperNextEl,
				prevEl: swiperPrevEl,
			},
			breakpoints: {
				// >= 320px
				640: {
					slidesPerView: 3,
					slidesPerGroup: 3,
				},
				// >= 480px
				1024: {
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
				// >= 640px
				1200: {
					slidesPerView: 5,
					slidesPerGroup: 5,
				}
			}
		});
	}
	on(window, 'load', function () {
		pageScrollAdd(indexPartnerCarousel, indexPartnerBuild);
	});
	/* ---------------------------------------- [END] Partner */

	/* ---------------------------------------- [START] 整頁 Scroll 監測 (After) */
	var pageScrollThrottle = null;
	var pageScrollHandler = function () {
		for (var i = 0; i < pageScrollCheckList.length; i++) {
			if (isInViewport(pageScrollCheckList[i].el)) {
				pageScrollCheckList[i].fun();
				pageScrollCheckList[i].build = true;
			}
		}

		// Clear
		pageScrollClear();

		// Stop
		if (pageScrollCheckList.length === 0) {
			off(window, 'scroll', pageScrollThrottle);
		}
	};

	on(window, 'load', function() {
		pageScrollThrottle = throttle(pageScrollHandler, 50, 1000); // 節流作用
		on(window, 'scroll', pageScrollThrottle);
		pageScrollHandler();
	});
	/* ---------------------------------------- [END] 整頁 Scroll 監測 (After) */
})(window, document);
